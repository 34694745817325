import { makeStyles } from "@toolkit/ui";

export const useAppointmentHistoryCardActionsSectionStyles = makeStyles()(theme => ({
  section: {
    paddingTop: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.divider}`,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
  },

  button: {
    borderRadius: "24px",
    height: "40px",

    textTransform: "none",
    display: "flex",
  },

  rescheduleButton: {
    padding: "8px 16px",
  },

  confirmButton: {
    extend: "button",
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
  },
  payNowButton: {
    extend: "button",
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.common.white,
  },

  rejoinButton: {
    extend: "button",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  divider: {
    marginTop: "5px",
  },
  closeButton: {
    marginTop: 10,
  },
}));
